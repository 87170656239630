import { useEffect, useReducer, useRef, useState } from 'react';
import { DictionaryService, type Dictionary } from 'service/dictionary/dictionaryService';
import { PromotionForm, type PromotionFormProps } from '../PromotionForm';
import { SuccessMessage } from '../PromotionForm/components/SuccessMessage';
import { ErrorMessage } from '../styles';
import { Controls } from './components/Controls';
import { Links, TLink } from './components/Links';
import { StepContent } from './components/StepContent';
import type { AvailableFields } from './components/StepFields/Mapper';
import { StepInfo } from './components/StepInfo';
import { ActionTypes } from './state/actions';
import { StepValue, reducer } from './state/reducer';
import { Title } from '../../Typography';
import { TrackingService } from '../../../service/tracking/trackingService';
import { useInView } from '../../../hooks/useInView';

export type Step = {
  title: string;
  description?: string;
  image?: {
    altText?: string;
    mimeType?: string;
    width: number;
    height: number;
    url: string;
  };
  formField: AvailableFields;
};

export type MultiStepPromotionFormProps = {
  steps: Step[];
  footerLinks: TLink[];
  translations: Dictionary;
  genderTranslations: Dictionary;
  salutationTranslations: Dictionary;
  promotionDownload?: {
    file: {
      url: string;
      fileName: string;
    };
    localizations?: {
      buttonText: string;
    }[];
  };
  promotionForm: Omit<
    PromotionFormProps,
    | 'translations'
    | 'promotionAssets'
    | 'genderTranslations'
    | 'salutationTranslations'
    | 'subscriptionTranslations'
    | 'onSubmitSuccess'
    | 'onSubmitError'
  > & {
    title: string;
    description: string;
    submitButtonText: string;
    submitSuccessTitle: string;
    submitSuccessDescription: string;
  };
  showOnlyDownloadButton?: boolean;
};

export const MultiStepPromotionForm = ({
  steps,
  footerLinks,
  translations,
  salutationTranslations,
  genderTranslations,
  promotionForm,
  promotionDownload,
  showOnlyDownloadButton,
}: MultiStepPromotionFormProps) => {
  const [state, dispatch] = useReducer(reducer, {
    currentStepIndex: 0,
    stepValues: [],
    formSubmitted: false,
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const promotionFormRef = useRef<HTMLFormElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentStep = steps[state.currentStepIndex];
  const isInvalidFieldValue = !state.stepValues[state.currentStepIndex]?.isValid === true;
  const isLastStep = state.currentStepIndex === steps.length;
  const submitErrorMessage = DictionaryService.getCopy(
    'submitErrorMessage',
    translations.dictionaryItems,
  );

  const inView = useInView(containerRef, { freeze: true, threshold: 0.75 });

  useEffect(() => {
    if (promotionForm && inView) {
      TrackingService.viewPromotion(promotionForm.internationalPromotionId);
    }
  }, [promotionForm, inView]);

  const handleControlsClick = (action: ActionTypes.NEXT_STEP | ActionTypes.PREVIOUS_STEP) => {
    if (isInvalidFieldValue && action === ActionTypes.NEXT_STEP) {
      setShowErrorMessage(true);
      return;
    }

    if (state.stepValues[state.currentStepIndex]) {
      TrackingService.promotionStep(
        state.currentStepIndex,
        state.stepValues[state.currentStepIndex].value,
        promotionForm.internationalPromotionId,
      );
    }

    setShowErrorMessage(false);
    dispatch({ type: action });
  };

  const handleFieldValueChange = (payload: StepValue) => {
    dispatch({ type: ActionTypes.CHANGE_STEP_VALUE, payload });
  };

  const handlePromotionFormSubmitSuccess = () => {
    dispatch({ type: ActionTypes.FORM_SUBMIT_SUCCESS });
    TrackingService.promotionSubmit(promotionForm.internationalPromotionId);
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePromotionFormSubmitError = () => {
    setShowErrorMessage(true);
  };

  const handleControlsSubmit = () => {
    promotionFormRef.current?.requestSubmit();
  };

  const mapStepValuesToPromotionAssets = () => {
    return state.stepValues.map((stepValue) => {
      return {
        question: stepValue.title,
        answer: stepValue.value,
      };
    });
  };

  if (showOnlyDownloadButton && promotionDownload) {
    return (
      <div
        className="multi-step-promotion-form max-w-[840px] mx-auto my-10 flex items-center flex-col space-y-5"
        ref={containerRef}
      >
        <Title tag="h2" type="sm" className="text-center mb-1 font-medium">
          {promotionForm.title}
        </Title>
        <a
          href={promotionDownload.file.url}
          download={promotionDownload.file.fileName}
          target="_blank"
          className="mb-10 border border-[#A45A2A] px-4 py-2 rounded-full cursor-pointer"
          onClick={() =>
            TrackingService.clickPromotionDownloadBtn(
              'button',
              promotionForm.internationalPromotionId,
            )
          }
        >
          {promotionDownload.localizations?.[0]?.buttonText ?? 'Download'}
        </a>
        <Links links={footerLinks} />
      </div>
    );
  }

  return (
    <div className="multi-step-promotion-form max-w-[840px] mx-auto" ref={containerRef}>
      {!state.formSubmitted && (
        <StepInfo
          step={
            isLastStep
              ? { title: promotionForm.title, description: promotionForm.description }
              : currentStep
          }
          currentStep={state.currentStepIndex + 1}
          stepCount={steps.length + 1}
          translations={translations}
        />
      )}
      {!isLastStep ? (
        <>
          <StepContent
            step={currentStep}
            stepValues={state.stepValues}
            onFieldValueChange={handleFieldValueChange}
            showErrorMessage={showErrorMessage}
          />
        </>
      ) : state.formSubmitted ? (
        <div className="flex flex-col items-center">
          <SuccessMessage
            title={promotionForm.submitSuccessTitle}
            description={promotionForm.submitSuccessDescription}
          />
          {promotionDownload && (
            <a
              href={promotionDownload.file.url}
              download={promotionDownload.file.fileName}
              target="_blank"
              className="mb-10 border border-[#A45A2A] px-4 py-2 rounded-full cursor-pointer"
              onClick={() =>
                TrackingService.clickPromotionDownloadBtn(
                  'form',
                  promotionForm.internationalPromotionId,
                )
              }
            >
              {promotionDownload.localizations?.[0]?.buttonText ?? 'Download'}
            </a>
          )}
        </div>
      ) : (
        <>
          <PromotionForm
            translations={translations}
            salutationTranslations={salutationTranslations}
            genderTranslations={genderTranslations}
            promotionAssets={mapStepValuesToPromotionAssets()}
            onSubmitSuccess={handlePromotionFormSubmitSuccess}
            onSubmitError={handlePromotionFormSubmitError}
            ref={promotionFormRef}
            {...promotionForm}
          />
          {showErrorMessage && <ErrorMessage>{submitErrorMessage}</ErrorMessage>}
        </>
      )}
      {!state.formSubmitted && (
        <Controls
          isBackButtonVisible={state.currentStepIndex !== 0}
          isLastStep={isLastStep}
          onControlsClick={handleControlsClick}
          onSubmit={handleControlsSubmit}
          translations={translations}
          submitText={promotionForm.submitButtonText}
        />
      )}
      <Links links={footerLinks} />
    </div>
  );
};

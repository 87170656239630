import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message';
import { SelectBoxArrowDown } from '@icons/SelectBoxArrowDown';
import { getLabel } from 'components/Forms/DynamicForm/components/Inputs/helper';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { Dictionary, DictionaryService } from 'service/dictionary/dictionaryService';
import { ErrorMessage, FieldContainer, FormLabel, MainLabel, Select } from '../../../styles';

type Option = { value: string; label: string };

type BasicSelectInputProps = {
  options: Option[];
  fieldName: string;
  translations: Dictionary;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

export const BasicSelectInput = ({
  options,
  translations,
  fieldName,
  register,
  errors,
  required,
}: BasicSelectInputProps) => {
  const requiredErrorMessage = DictionaryService.getCopy(
    'requiredMessage',
    translations.dictionaryItems,
  );
  const defaultSelectOption = DictionaryService.getCopy(
    'defaultSelectOption',
    translations.dictionaryItems,
  );
  const label = DictionaryService.getCopy(`${fieldName}Label`, translations.dictionaryItems);

  return (
    <FieldContainer>
      <MainLabel>
        <FormLabel tag="label" type="lead">
          {getLabel({ label, required })}
        </FormLabel>
        <Select {...register(fieldName, { required: required && requiredErrorMessage, value: '' })}>
          <option value="" disabled>
            {defaultSelectOption}
          </option>
          {options.map(({ value, label }: Option) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Select>
        <div className="absolute right-5 bottom-3">
          <SelectBoxArrowDown />
        </div>
      </MainLabel>
      <RHFErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }: { message: string }) => <ErrorMessage>{message}</ErrorMessage>}
      />
    </FieldContainer>
  );
};
